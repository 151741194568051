import React from 'react'
import { Router } from "@reach/router"
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import styled from 'styled-components'
import PrivateRoute from "../components/PrivateRoute"
import Profile from "../components/User/Profile"
import Login from "../components/User/Login"
import { getUser, isLoggedIn } from "../components/User/User"

const Wrapper = styled.div`
  margin: 0 auto 5rem auto !important;
  padding: 5rem 0 5rem 0;
  max-width: 600px;
  @media (max-width: 768px) {
    max-width: 90vw;
  }
`

const User = ({ data }) => {
  const postNode = {
    title: `User Account - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{`User Account - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="user" customTitle />

      <Wrapper>
        <PageTitle>User Account</PageTitle>
        <Router>
          <PrivateRoute path="/user" component={Profile} />
          <Login path="/user/login"/>
        </Router>
      </Wrapper>
    </Layout>
  )
}

export default User
