import React, {Component} from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import {Form, Alert} from 'react-bootstrap';
import CTAButton from '../utils/CTAButton'
import Checkbox from '../utils/Checkbox'
import { handleLogin, handleSignup, isLoggedIn, isBrowser  } from "../User/User"

const StyledForm = styled(Form)`
  font-size: 1.25rem;
`

class SignupForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      preferredStore: '',
      optin: true,
      alert: null
    }
  }
    
  handleSubmit = event => {
    event.preventDefault()
    
    if (this.state.password != this.state.confirmPassword) {
      this.setState({'alert': {
        type: "danger",
        message: "Error: Passwords do not match."
      }})
      return;
    }
    
    handleSignup(this.state).then( (response) => {
      if (response.result == "OK") {
        if (isBrowser()) window.location.reload();
      } else {
        this.setState({'alert': {
          type: "danger",
          message: "Error: " + response.message
        }})
      }
    });
  }
  
  handleInputChange = event => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [event.target.name]: value,
    })
  }
  
  clearForm = () => {
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      preferredStore: '',
      optin: true,
      alert: null
    });
  }

  render() {
    let alert;
    if (this.state.alert) {
      alert = <Alert variant={this.state.alert.type}>{this.state.alert.message}</Alert>
    }
    
    const stores = this.props.data.allContentfulStore.edges.map(({ node: store }) => {return store})
    const storeOptions = stores.map((store, index) => (
      <option key={store.loyaltyLaneStoreId} value={store.loyaltyLaneStoreId}>{`${store.storeName}, MS`}</option>
    ))
    
    return (
      <StyledForm name="signup" onSubmit={this.handleSubmit}>
        <p className="text-center">Sign up for a new Reed's Market account</p>
        {alert}
        <Form.Group controlId="formFirstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            name="firstName"
            type="text"
            placeholder="Enter first name"
            value={this.state.firstName}
            onChange={this.handleInputChange}
            required
          />
        </Form.Group>
        
        <Form.Group controlId="formLastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control 
            name="lastName"
            type="text"
            placeholder="Enter last name"
            value={this.state.lastName}
            onChange={this.handleInputChange}
            required
          />
        </Form.Group>
        
        <Form.Group controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            name="email"
            type="email"
            placeholder="Enter email"
            value={this.state.email}
            onChange={this.handleInputChange}
            required
          />
        </Form.Group>
        
        <Form.Group controlId="formPhone">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            name="phone"
            type="text"
            placeholder="Enter phone number"
            value={this.state.phone}
            onChange={this.handleInputChange}
            required
          />
        </Form.Group>
      
        <Form.Group controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            name="password"
            type="password"
            placeholder="Password"
            value={this.state.password}
            onChange={this.handleInputChange}
            required
          />
        </Form.Group>
        
        <Form.Group controlId="formConfirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control 
            name="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            value={this.state.confirmPassword}
            onChange={this.handleInputChange}
            required
          />
        </Form.Group>
        
        <Form.Group controlId="formPreferredStore">
          <Form.Label>Preferred Store</Form.Label>
          <Form.Control 
            name="preferredStore"
            as="select"
            value={this.state.preferredStore}
            onChange={this.handleInputChange}
          >
            <option value="">Select Store (optional)</option>
            {storeOptions}
          </Form.Control>
        </Form.Group>
        
        <Form.Group controlId="formOptin">
          <Form.Check 
            name="optin"
            type="checkbox"
            label="I want to receive email updates"
            checked={this.state.optin}
            onChange={this.handleInputChange}
          />
        </Form.Group>
        {alert}
        <div className="float-right">
          <CTAButton variant="modal" size="small" type="submit">Sign Up</CTAButton>
        </div>
      </StyledForm>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulStore(sort: {fields: [reedsBrandedStore], order: [DESC]}){
          edges {
            node {
              id
              storeName
              localName
              loyaltyLaneStoreId
            }
          }
        }
      }
    `}
    render={data => <SignupForm data={data} {...props} />}
  />
);
