import React from "react"
import { navigate } from "gatsby"
import { isLoggedIn, isBrowser } from "./User/User"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (isBrowser() && !isLoggedIn() && location.pathname !== `/user/login`) {
    navigate("/user/login");
    return null;
  }
  return <Component {...rest} />
}

export default PrivateRoute