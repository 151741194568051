import React, {Component} from 'react'
import { StaticQuery, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import {Form, Alert} from 'react-bootstrap';
import CTAButton from '../utils/CTAButton'
import Checkbox from '../utils/Checkbox'
import { getUser, getUserToken, fetchUser, updateAccount, updateShopper, isLoggedIn } from "../User/User"

const StyledForm = styled(Form)`
  font-size: 1.25rem;
`

class ProfileForm extends React.Component {
  constructor(props) {
    super(props)
    
    let user = isLoggedIn() ? getUser() : null;
    
    let phone = '';
    if (user && user.HomePhone) {
      phone = user.HomePhone
    } else if (user && user.MobilePhone) {
      phone = user.MobilePhone
    }
    
    this.state = {
      firstName: user ? user.FirstName : '',
      lastName: user ? user.LastName : '',
      email: user ? user.Email : '',
      homeStoreID: user ? user.HomeStoreID : '',
      optin: user ? user.Optin : true,
      phone: phone,
      password: '',
      confirmPassword: '',
    }
  }
  
  goBack = event => {
    event.preventDefault();
    navigate(-1); 
  }
  
  handleSubmit = event => {
    event.preventDefault()
    
    function isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }
    
    let user = getUser();
    
    let updateShopperData = {};
    let updateAccountData = {};
    
    if (this.state.firstName && (this.state.firstName != user.FirstName)) {
      updateShopperData.firstName = this.state.firstName;
    }
    
    if (this.state.lastName && (this.state.lastName != user.LastName)) {
      updateShopperData.lastName = this.state.lastName;
    }
    
    if (this.state.email && (this.state.email != user.Email )) {
      updateShopperData.emailaddress = this.state.email;
    }
    
    if (this.state.phone) {
      if (user.HomePhone && (this.state.phone != user.HomePhone)) {
        updateShopperData.homephonenumber = this.state.phone;
      } else if (this.state.phone != user.MobilePhone) {
        updateShopperData.mobilephonenumber = this.state.phone;
      }
    }
    
    if (this.state.password) {
      if (this.state.password == this.state.confirmPassword) {
        updateShopperData.password = this.state.password;
      } else {
        this.setState({'alert': {
          type: "danger",
          message: "Error: Passwords do not match."
        }})
        return;
      }
    }
    
    if (this.state.optin != user.Optin) {
      updateShopperData.optin = this.state.optin;
    }
    
    if ((user.HomeStoreID != this.state.homeStoreID)) {
      updateAccountData.homeStoreid = this.state.homeStoreID;
      updateAccountData.accountid = user.AccountId;
    }
    
    if (!isEmpty(updateShopperData)) {
      updateShopperData.shopperid = user.ID;
    }
    
    if (getUserToken()) {
      if (!isEmpty(updateAccountData) && !isEmpty(updateShopperData)) {
        updateAccount(getUserToken(), updateAccountData).then( (response) => {
          if (response.result == "OK") {
            
            updateShopper(getUserToken(), updateShopperData).then( (response) => {
              if (response.result == "OK") {
                this.setState({'alert': {
                  type: "success",
                  message: "Your account has been updated."
                }})
                fetchUser(getUserToken());
              } else {
                this.setState({'alert': {
                  type: "danger",
                  message: "Error: " + response.message
                }})
              }
            });
          } else {
            this.setState({'alert': {
              type: "danger",
              message: "Error: " + response.message
            }})
          }
        });
      } else if (!isEmpty(updateAccountData)) {
        updateAccount(getUserToken(), updateAccountData).then( (response) => {
          if (response.result == "OK") {
            this.setState({'alert': {
              type: "success",
              message: "Your account has been updated."
            }})
            fetchUser(getUserToken());
          } else {
            this.setState({'alert': {
              type: "danger",
              message: "Error: " + response.message
            }})
          }
        });
      } else if (!isEmpty(updateShopperData)) {
        updateShopper(getUserToken(), updateShopperData).then( (response) => {
          if (response.result == "OK") {
            this.setState({'alert': {
              type: "success",
              message: "Your account has been updated."
            }})
            fetchUser(getUserToken());
          } else {
            if (response.message.includes("Duplicate fields")) {
              if (response.message.includes("MobilePhone") && response.message.includes("Email")){
                this.setState({'alert': {
                  type: "danger",
                  message: "Error: This email and phone number are already registered."
                }})
              } else if (response.message.includes("MobilePhone")) {
                this.setState({'alert': {
                  type: "danger",
                  message: "Error: This phone number is already registered."
                }})
              } else if (response.message.includes("Email")) {
                this.setState({'alert': {
                  type: "danger",
                  message: "Error: This email is already registered."
                }})
              } else {
                this.setState({'alert': {
                  type: "danger",
                  message: "Error: " + response.message
                }})
              }
            } else {
              this.setState({'alert': {
                type: "danger",
                message: "Error: " + response.message
              }})
            }
          }
        });
      }
    } else {
      this.setState({'alert': {
        type: "danger",
        message: "Error: Missing user token."
      }})
    }
  }

  handleInputChange = event => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [event.target.name]: value,
    })
  }
  
  clearForm = () => {
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      homeStoreID: '',
      optin: true
    });
  }

  render() {
    let alert;
    if (this.state.alert) {
      alert = <Alert variant={this.state.alert.type}>{this.state.alert.message}</Alert>
    }
    
    const stores = this.props.data.allContentfulStore.edges.map(({ node: store }) => {return store})
    const storeOptions = stores.map((store, index) => (
      <option key={store.loyaltyLaneStoreId} value={store.loyaltyLaneStoreId}>{`${store.storeName}, MS`}</option>
    ))
    
    return (
      <StyledForm name="profile" onSubmit={this.handleSubmit}>
        <p className="text-center">Update your Reed's Market account</p>
        {alert}
        <Form.Group controlId="formFirstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            name="firstName"
            type="text"
            placeholder="Enter first name"
            value={this.state.firstName}
            onChange={this.handleInputChange}
            required
          />
        </Form.Group>
        
        <Form.Group controlId="formLastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control 
            name="lastName"
            type="text"
            placeholder="Enter last name"
            value={this.state.lastName}
            onChange={this.handleInputChange}
            required
          />
        </Form.Group>
        
        <Form.Group controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            name="email"
            type="email"
            placeholder="Enter email"
            value={this.state.email}
            onChange={this.handleInputChange}
            required
          />
        </Form.Group>
        
        <Form.Group controlId="formPhone">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            name="phone"
            type="text"
            placeholder="Enter phone number"
            value={this.state.phone}
            onChange={this.handleInputChange}
            required
          />
        </Form.Group>
      
        <Form.Group controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            name="password"
            type="password"
            placeholder="Password"
            value={this.state.password}
            onChange={this.handleInputChange}
          />
        </Form.Group>
        
        <Form.Group controlId="formConfirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control 
            name="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            value={this.state.confirmPassword}
            onChange={this.handleInputChange}
          />
        </Form.Group>
        
        <Form.Group controlId="formHomeStoreID">
          <Form.Label>Preferred Store</Form.Label>
          <Form.Control 
            name="homeStoreID"
            as="select"
            value={this.state.homeStoreID}
            onChange={this.handleInputChange}
          >
            <option value="">Select Store (optional)</option>
            {storeOptions}
          </Form.Control>
        </Form.Group>
        
        <Form.Group controlId="formOptin">
          <Form.Check 
            name="optin"
            type="checkbox"
            label="I want to receive email updates"
            checked={this.state.optin}
            onChange={this.handleInputChange}
          />
        </Form.Group>
        {alert}
        <div className="float-right">
          <CTAButton variant="solid-white" size="small" type="submit">Update</CTAButton>
        </div>
        <div className="float-left">
          <CTAButton variant="solid-white" size="small" onClick={this.goBack}>{[String.fromCharCode(8592)," Back"]}</CTAButton>
        </div>
      </StyledForm>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulStore(sort: {fields: [reedsBrandedStore], order: [DESC]}){
          edges {
            node {
              id
              storeName
              localName
              loyaltyLaneStoreId
            }
          }
        }
      }
    `}
    render={data => <ProfileForm data={data} {...props} />}
  />
);
