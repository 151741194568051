import React from "react"
import { navigate } from "gatsby"
import styled from 'styled-components'
import {Container, Row, Col, Nav, Tab} from 'react-bootstrap';
import { isLoggedIn, isBrowser } from "./User"
import LoginForm from "../UserForms/LoginForm"
import SignupForm from "../UserForms/SignupForm"

const TabNav = styled(Nav)`
	display: flex;
	justify-content: space-around;
	margin-bottom: 2rem;
	
	.nav-link {
		border-radius: 0;
		font-family: 'montserrat', sans-serif;
		font-size: 1.25rem;
		font-weight: 600;
		text-transform: uppercase;
		color: ${props => props.theme.colors.base};
		&.active {
			color: ${props => props.theme.colors.white};
			background-color: ${props => props.theme.colors.base};
			padding: .5rem 3rem;
		}
	}
`

class Login extends React.Component {
  state = {
    activeTab: 'signin'
  }
  
  componentDidMount(){
    if (isBrowser()) {
      let hash = window.location.hash.replace('#', '');
      if (hash === "signup") {
        this.setState({activeTab: 'signup'});
      }
    }
  }
  
  render() {
    if (isBrowser() && isLoggedIn()) {
      navigate('/user/')
    }
    return (
      <>
      <Tab.Container id="user-form-tabs" defaultActiveKey={this.state.activeTab}>
        <TabNav variant="pills" className="flex-row">
          <Nav.Item>
            <Nav.Link eventKey="signin">Sign In</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="signup">Create an Account</Nav.Link>
          </Nav.Item>
        </TabNav>
        <Tab.Content>
          <Tab.Pane eventKey="signin">
            <LoginForm/>
          </Tab.Pane>
          <Tab.Pane eventKey="signup">
            <SignupForm/>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      </>
    )
  }
}
export default Login
