import React, {Component} from 'react'
import styled from 'styled-components'
import {Form, Alert} from 'react-bootstrap';
import CTAButton from '../utils/CTAButton'
import { handleLogin, isLoggedIn, isBrowser } from "../User/User"

const StyledForm = styled(Form)`
  font-size: 1.25rem;
`

export default class LoginForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      alert: null
    }
  }
    
  handleSubmit = event => {
    event.preventDefault();
    handleLogin(this.state).then( (response) => {
      if (response.result == "OK") {
        if (isBrowser()) window.location.reload();
      } else {
        this.setState({'alert': {
          type: "danger",
          message: "Error: " + response.message
        }})
      }
    });;
  }
  
  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  
  clearForm = () => {
    this.setState({
      username: '',
      password: '',
      alert: null
    });
  }

  render() {
    let alert;
    if (this.state.alert) {
      alert = <Alert variant={this.state.alert.type}>{this.state.alert.message}</Alert>
    }
    
    return (
      <StyledForm name="login" onSubmit={this.handleSubmit} >
        <p className="text-center">Sign in to your Reed's Market account</p>
        {alert}
        <Form.Group>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            name="username"
            type="email"
            placeholder="Enter email"
            value={this.state.username}
            onChange={this.handleInputChange}
            required
          />
        </Form.Group>
      
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            name="password"
            type="password"
            placeholder="Password"
            value={this.state.password}
            onChange={this.handleInputChange}
            required
          />
        </Form.Group>
        <div className="float-left small"><a href="/forgotpassword">Forgot your password?</a></div>
        <div className="float-right">
          <CTAButton variant="solid-white" size="small" type="submit">Sign In</CTAButton>
        </div>
      </StyledForm>
    )
  }
}
